import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

function ProductItem(props) {
  const [dimension] = useOutlet('dimension');
  const {product, onClick, mobile} = props;

  return (
    <ProductWrapper
      mobile={mobile}
      onClick={onClick}
      maxWidth={mobile ? 240 : 280}>
      <div className="container">
        <img
          alt="product-img"
          src={product.images[0]?.expected_url || '../../images/empty-img.png'}
          style={{marginBottom: 10}}
        />
        <div style={{marginBottom: 6}}>{product.kickoff || ''}</div>
        <div style={{marginBottom: 10, height: 40, overflow: 'hidden'}}>
          {product.name}
        </div>
        <div className="origin">${product.original_price || 0}</div>
        <div>${product.price}</div>
      </div>
    </ProductWrapper>
  );
}

const ProductWrapper = styled.div`
  margin: 0 auto;
  max-width: ${(props) => props.maxWidth}px;
  width: 100%;
  height: 450px;
  position: relative;

  & > .container {
    width: 100%;
    min-height: 389px;
    position: absolute;

    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    & > img {
      width: ${(props) => props.maxWidth - 20}px;
      height: ${(props) => (props.mobile ? 240 : 280)}px;
      object-fit: cover;
      border-radius: 10px;
    }

    & > div {
      font-weight: 300;
      font-size: 14px;
      color: var(--textColor);
      letter-spacing: 0.4px;
      text-align: center;
      line-height: 20px;
    }

    & > div.origin{
        text-decoration: line-through;
      letter-spacing: 0.29px;
      font-weight: 300;
      font-size: 10px;
      color: #979797; 
    }

    :hover {
      background: rgba(218, 203, 189, 0.4);
    }

    :active {
      top: 2px;
      left: 2px;
    }
`;

export default ProductItem;