import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BannerCarousel from './BannerCarousel';
import FindSection from './FindSection';
import HotProductsSection from './HotProductsSection';
import ChannelSection from './ChannelSection';
import CooperationSection from './CooperationSection';
import * as Widget from '../../../Components/Widget';
import * as AppActions from '../../../AppActions';

function Landing(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const {landing} = props;

  return (
    <Wrapper mobile={mobile}>
      <div
        className="full-screen-width"
        style={{paddingTop: 0, paddingBottom: 0}}>
        <BannerCarousel landing={landing} />
      </div>

      <div className="full-screen-width">
        <div className="constraint">
          <div className="kickoff">Find Your Coffee</div>
          <div className="title">找到屬於您的品味</div>
          <FindSection />
        </div>
      </div>

      <div className="full-screen-width">
        <div className="constraint">
          <Widget.Row justify="space-between">
            <div style={{visibility: 'hidden'}}>
              <SeeMoreButton url="/products" />
            </div>
            <Widget.Col css="flex: 1;">
              <div className="kickoff">Hot</div>
              <div className="title">熱門商品</div>
            </Widget.Col>
            <SeeMoreButton url="/products" />
          </Widget.Row>
          <HotProductsSection />
        </div>
      </div>

      <div className="full-screen-width">
        <div className="constraint">
          <div className="kickoff">Hecafe Recommended</div>
          <div className="title">好咖推薦</div>
          <ChannelSection landing={landing} />
        </div>
      </div>

      <div
        className="full-screen-width"
        style={{
          backgroundColor: 'rgba(218, 203, 189, 0.2)',
        }}>
        <div className="constraint">
          <div className="kickoff">Cooperation</div>
          <div className="title">企業合作</div>
          <CooperationSection />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & .full-screen-width {
    width: 100%;
    padding: 50px 0px;
  }

  & .constraint {
    margin: 0px auto;
    max-width: var(--contentMaxWidth);
    width: 100%;
  }

  & .kickoff {
    margin-bottom: 8px;
    text-align: center;
    font-size: ${(props) => (props.mobile ? '10' : '14')}px;
    color: var(--textColor);
    letter-spacing: 0.4px;
  }

  & .title {
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
    font-size: ${(props) => (props.mobile ? '18' : '22')}px;
    color: var(--textColor);
    letter-spacing: 0.63px;
  }

  & .button {
    cursor: pointer;
    border: 0px;
    padding: 10px;
    background-color: transparent;
    font-weight: 300;
    font-size: 14px;
    color: var(--textColor);
  }
`;

const SeeMoreButton = (props) => {
  let {url} = props;

  return (
    <button className="button" onClick={() => AppActions.navigate(url)}>
      探索更多→
    </button>
  );
};

export default Landing;