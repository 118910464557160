import React from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import * as AppActions from '../../../AppActions';
import * as Widget from '../../../Components/Widget';

function CooperationSection(props) {
  return (
    <Wrapper>
      <Widget.Row wrap="wrap" css="margin-bottom: 14px;">
        <Widget.Row>
          {['/images/home-coffee-01.png', '/images/home-coffee-02.png'].map(
            (src, index) => (
              <img
                alt="home-coffee"
                src={src}
                key={`front-${index}`}
                style={{margin: 10, width: 60, height: 60}}
              />
            ),
          )}
        </Widget.Row>
        <Widget.Row>
          {['/images/home-coffee-03.png', '/images/home-coffee-04.png'].map(
            (src, index) => (
              <img
                alt="home-coffee"
                src={src}
                key={`back-${index}`}
                style={{margin: 10, width: 60, height: 60}}
              />
            ),
          )}
        </Widget.Row>
      </Widget.Row>
      <div className="text-container">
        若您對好咖嚴選的通路合作或商品聯名販售有興趣，歡迎填寫表單與我們聯絡！
        也可直接來信至： service@hecafe.com.tw
      </div>
      <div className="button-container">
        <Button
          type="primary"
          onClick={() => AppActions.navigate('/customer-support')}
          style={{border: 0, minWidth: 200}}>
          聯絡我們
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .text-container {
    margin-bottom: 50px;
    padding: 10px 20px;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
  }

  & > .button-container {
    display: flex;
    justify-content: center;
  }
`;

export default CooperationSection;