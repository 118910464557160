import React from 'react';
import styled from 'styled-components';
import {Carousel} from 'react-responsive-carousel';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ProductItem from './ProductItem';

function HotProductsSection(props) {
  let [dimension] = useOutlet('dimension');
  let [actions] = useOutlet('actions');
  let [slide, setSlide] = React.useState(0);
  let [products, setProducts] = React.useState(null);
  let displayCount = dimension.innerWidth >= 568 ? 3 : 1;
  const mobile = dimension.rwd === 'mobile';

  React.useEffect(() => {
    async function fetchHotProducts() {
      try {
        let resp = await JStorage.fetchDocuments('product', {
          labels: 'hot',
        });
        setProducts(await resp.results);
      } catch (err) {
        console.warn(err);
      }
    }

    fetchHotProducts();
  }, []);

  return (
    <Wrapper>
      <Carousel
        centerMode
        centerSlidePercentage={100 / displayCount}
        selectedItem={slide}
        showIndicators
        infiniteLoop
        useKeyboardArrows
        autoPlay
        stopOnHover
        swipeable
        emulateTouch
        showThumbs={false}
        showStatus={false}>
        {!products ? (
          <div style={{height: 450}} />
        ) : (
          products.map((item) => (
            <ProductItem
              key={item.id}
              mobile={mobile}
              product={item}
              onClick={() =>
                actions.navigate(`/product?id=${item.id}`, {loading: true})
              }
            />
          ))
        )}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default HotProductsSection;