import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {Carousel} from 'react-responsive-carousel';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

function BannerCarousel(props) {
  const {landing} = props;
  let [dimension] = useOutlet('dimension');

  return (
    <Wrapper innerWidth={dimension.innerWidth}>
      <Carousel
        showArrows
        showIndicators
        infiniteLoop
        useKeyboardArrows
        autoPlay
        stopOnHover
        swipeable
        emulateTouch
        showThumbs={false}
        showStatus={false}>
        {landing &&
          landing.custom_layout?.hero_banner.images.map((banner, index) => (
            <div key={banner.id} className="slide">
              <img
                className="slide-img"
                alt={`banner-${index}`}
                src={banner.expected_url}
              />
            </div>
          ))}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: ${(props) =>
    props.innerWidth
      ? props.innerWidth >= 768
        ? 400
        : props.innerWidth >= 576
        ? 200
        : 150
      : 400}px;

  & .slide {
    cursor: pointer;
  }

  & .slide-img {
    width: 100%;
    object-fit: contain;
  }
`;

export default BannerCarousel;