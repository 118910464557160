import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../../AppActions';

function FindSection(props) {
  const [dimension] = useOutlet('dimension');

  return (
    <Wrapper>
      {[
        {
          icon: '/images/home-find-01.png',
          text: '尋香氣',
          path: '/products/flavor',
        },
        {
          icon: '/images/home-find-02.png',
          text: '找焙度',
          path: '/products/roast',
        },
        {
          icon: '/images/home-find-03.png',
          text: '搜產地',
          path: '/products/origin',
        },
      ].map((item, index) => (
        <ItemButtonWrapper
          key={index}
          className="container"
          bgImg="/images/home-find-button-bg.png"
          onClick={() => AppActions.navigate(item.path)}>
          <div className="container">
            <img
              src={item.icon}
              alt="find-icon"
              style={{width: 55, height: 55, marginBottom: 10}}
            />
            <div>{item.text}</div>
          </div>
        </ItemButtonWrapper>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 568px) {
    flex-direction: column;
  }
`;

const ItemButtonWrapper = styled.div`
  margin: 10px;
  cursor: pointer;
  position: relative;
  width: 180px;
  height: 180px;

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url(${(props) => props.bgImg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;

    position: absolute;

    :active {
      top: 3px;
      left: 3px;
    }
  }

  @media screen and (max-width: 568px) {
    width: 150px;
    height: 150px;
  }
`;

export default FindSection;