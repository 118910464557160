import styled from 'styled-components';

const PrimaryColor = '#765B41';
const SecondColor = '#bd9f84';
const ThirdColor = '#dacbbd';
const TextColor = '#0e0e0e';

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  flex-wrap: ${(props) => props.wrap || 'no-wrap'};

  ${(props) => props.css || ''}
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align || 'center'};
  flex-wrap: ${(props) => props.wrap || 'no-wrap'};

  ${(props) => props.css || ''}
`;

const SectionTitle = styled.h3`
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 22px;
  color: #0e0e0e;
  letter-spacing: 0.63px;
  line-height: 20px;
  text-align: center;
`;

const SectionDesp = styled.p`
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  letter-spacing: 1px;
  text-align: center;
  ${(props) => props.css || ''};
`;

export {
  PrimaryColor,
  SecondColor,
  ThirdColor,
  TextColor,
  Row,
  Col,
  SectionTitle,
  SectionDesp,
};