import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {Carousel} from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube';
import {useOutlet} from 'reconnect.js';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

let itemHeight = 200;

function ChannelSection(props) {
  const {landing} = props;
  let [dimension] = useOutlet('dimension');
  let [slide, setSlide] = React.useState(0);
  let displayCount =
    dimension.innerWidth >= 1000 ? 3 : dimension.innerWidth >= 650 ? 2 : 1;

  return (
    <Wrapper>
      <Carousel
        centerMode
        centerSlidePercentage={100 / displayCount}
        selectedItem={slide}
        useKeyboardArrows
        swipeable
        emulateTouch
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        style={{margin: 20}}
        renderArrowPrev={(onClick) => (
          <Button
            type="link"
            icon={<ArrowLeftOutlined size={30} />}
            onClick={onClick}
            style={{
              position: 'absolute',
              top: (itemHeight - 30) / 2,
              left: 0,
              zIndex: 100000,
            }}
          />
        )}
        renderArrowNext={(onClick) => (
          <Button
            type="link"
            icon={<ArrowRightOutlined size={30} />}
            onClick={onClick}
            style={{
              position: 'absolute',
              top: (itemHeight - 30) / 2,
              right: 0,
              zIndex: 100000,
            }}
          />
        )}>
        {!landing ? (
          <div style={{height: itemHeight}} />
        ) : (
          landing.custom_layout?.channel_video.map((video, index) => {
            return <VideoItem video={video} key={index} />;
          })
        )}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

function VideoItem(props) {
  let {video} = props;
  return <ReactPlayer url={video.link} width="330" height={itemHeight} />;
}

export default ChannelSection;